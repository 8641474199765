<template>
  <div class="courses-page" v-if="this.$store.state.Courses.courses">
    <section class="title-section">
      <div class="container">
        <h1 v-scroll-animation="'animate__slideInDown'">دورات</h1>
        <hr/>
      </div>
    </section>
    <section class="courses-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 mb-5" v-scroll-animation="'animate__zoomIn'" :key="item.id"
               v-for="item in this.$store.state.Courses.courses">
            <router-link :to="{name:'SingleCourse',params:{id:item.id}}" class="courseCard">
              <img class="img-fluid" :src="$store.state.generalStore.urlPrefixForImages+item.image" alt="Course Image">
              <h4>{{ item.title }}</h4>
              <hr/>
              <div class="course-info">
                <div class="info-item">
                  <font-awesome-icon icon="user"/>
                  <p><span>المدرب:</span> {{ item.course_trainer.name }}</p>
                </div>
                <div class="info-item">
                  <font-awesome-icon icon="calendar-alt"/>
                  <p><span>التاريخ:</span> {{ item.course_date }}</p>
                </div>
                <div class="info-item">
                  <font-awesome-icon icon="stopwatch"/>
                  <p><span>الوقت:</span>{{ item.course_total_hours }} ساعة</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="pagination-section">
      <pagination class="pagination-component" :per-page="perPage" v-model="currentPage"
                  :records="this.$store.state.Courses.courses.length"
                  @paginate="updatePagination"
                  :options="{chunk:4}"/>
    </section>
  </div>
</template>

<script>
import Pagination from 'vue-pagination-2';

export default {
  name: "courses",
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 9,
    };
  },
  computed: {
    displayCourses() {
      const startIndex = this.perPage * (this.currentPage - 1);
      const endIndex = startIndex + this.perPage;
      return this.courses.slice(startIndex, endIndex);
    },
  },
  methods: {
    updatePagination(val) {
      window.scrollTo(0, 0);
      // console.log(val);
    }
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store.dispatch("Courses/getCourses")
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("loading/hide");
          }, 500);
        })
        .catch(err => {
          console.log(err);
        })
  },
}
</script>

<style lang="scss">
.courses-page {
  padding: 3.5em 0 4.5em 0;
  background-color: #fbfcfc;
  overflow-x: hidden;

  .title-section {
    h1 {
      font-weight: 700;
      color: $myViolet;
    }
  }

  .courses-section {
    .courseCard {
      display: block;
      background-color: #ffffff;
      //box-shadow: 0 0 12px rgba(#000000, 0.1);
      border-radius: 8px;
      overflow: hidden;
      transition: 0.5s $bounceAnimation;

      //&:hover {
      //  transform: scale(1.05);
      //}

      img {
        border-radius: 8px;
        margin-bottom: 1em;
      }

      h4 {
        padding: 0 1em;
        font-weight: 700;
        color: $myBlue;
      }

      hr {
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: calc(100% - 2em);
        background-color: #323232;
      }

      .course-info {
        padding: 0.5em 1em;

        .info-item {
          display: flex;
          align-items: center;
          margin-bottom: 1em;

          p {
            margin-bottom: 0;
            color: #323232;

            span {
              margin-inline-end: 0.5em;
              font-weight: 700;
            }
          }

          svg {
            margin-inline-end: 0.5em;
            font-size: 1.2rem;
            color: $myViolet;
          }
        }
      }
    }
  }

  .pagination-section {
    margin-top: 3em;
    display: flex;
    justify-content: center;

    .pagination-component {
      .pagination {
        margin-bottom: 0;
        user-select: none;
      }

      .VuePagination__pagination-item-prev-chunk, .VuePagination__pagination-item-next-chunk, .VuePagination__count {
        display: none !important;
      }

      .page-item {
        //overflow: hidden;
        margin-inline-end: 0.5em;

        a {
          border-radius: 50%;
          border: none;
          color: $myBlue;
          background-color: #e7e9ec;
          font-weight: 700;
          padding: 0;
          width: 2em;
          height: 2em;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active {
            background-color: $myBlue;
            color: #ffffff;
          }
        }
      }

      .VuePagination__pagination-item-prev-page, .VuePagination__pagination-item-next-page {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }

        a {
          width: 2em;
          height: 2em;
          border-radius: 50%;
          color: $myViolet;
          border: 3px solid $myViolet;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }
    }
  }
}
@media screen and (max-width: 576px){
  .courses-page{
    .title-section{
      h1{
        font-size: 2rem;
      }
    }
  }
}
</style>