<template>
  <div class="404-page">
    <h1>404 Page</h1>
  </div>
</template>

<script>
export default {
  name: "notFoundPage",
  created() {
    this.$store.dispatch("loading/hide");
  }
}
</script>

<style lang="scss" scoped>

</style>