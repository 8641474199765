<template>
  <div class="singleCourse-page" v-if="courseInfo">
    <section class="title-section">
      <div class="container">
        <h1 v-scroll-animation="'animate__slideInDown'">دورات</h1>
        <hr/>
      </div>
    </section>
    <section class="course-main-info">
      <div class="container">
        <h2 v-scroll-animation="'animate__slideInDown'">{{ courseInfo.title }}</h2>
        <img v-scroll-animation="'animate__zoomIn'" class="img-fluid course-image"
             :src="this.$store.state.generalStore.urlPrefixForImages+courseInfo.image" alt="Course Image">
        <div class="course-details">
          <div class="row">
            <div class="col-md-4">
              <div class="detail-item with-traniner">
                <img v-scroll-animation="'animate__slideInDown'" class="img-fluid"
                     :src="this.$store.state.generalStore.urlPrefixForImages+courseInfo.course_trainer.image"
                     alt="Trainer Image"/>
                <div class="text-part" v-scroll-animation="'animate__slideInDown'">
                  <p class="label-item">المدرب:</p>
                  <p>{{ courseInfo.course_trainer.name }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="detail-item">
                <div class="text-part" v-scroll-animation="'animate__slideInDown'">
                  <p class="label-item">معلومات:</p>
                  <p>{{ courseInfo.information }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="detail-item last">
                <div class="text-part" v-scroll-animation="'animate__slideInDown'">
                  <p class="label-item">معلومات:</p>
                  <!--      TODO add the right route            -->
                  <router-link :to="{name:'',params:{id:courseInfo.form_id}}" class="signIn-btn">
                    <span>تسجيل</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="course-content" v-scroll-animation="'animate__slideInDown'">
      <div class="container">
        <div v-html="courseInfo.content"></div>
        <div v-html="courseInfo.course_trainer.about"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "singleCourse",
  data() {
    return {
      courseInfo: '',
    };
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store.dispatch("Courses/getSingleArticle", {id: this.$route.params.id})
        .then(() => {
          this.courseInfo = this.$store.state.Courses.singleCourse;
          setTimeout(() => {
            this.$store.dispatch("loading/hide");
          }, 500);
        })
        .catch(err => {
          console.log(err.message);
        })
  },
}
</script>

<style lang="scss" scoped>
.singleCourse-page {
  background-color: #fbfcfc;
  padding: 3.5em 0;

  .title-section {
    h1 {
      font-weight: 700;
      color: $myViolet;
    }
  }

  .course-main-info {
    h2 {
      margin-bottom: 0.75em;
      font-size: 1.7rem;
      font-weight: 700;
    }

    .course-image {
      border-radius: 8px;
      margin-bottom: 1.8em;
      width: 100%;
      height: 20em;
      object-fit: cover;
      object-position: center;
    }

    .course-details {
      .row {

      }

      .detail-item {
        display: flex;
        align-items: center;
        height: 100%;
        border-inline-end: 3px solid rgba(#000000, 0.3);

        &.last {
          border: none;
        }

        img {
          border-radius: 50%;
          width: 6em;
          height: 6em;
          object-fit: cover;
          object-position: center;
          margin-inline-end: 0.75em;
        }

        .text-part {
          p {
            margin-bottom: 0;
          }

          .label-item {
            margin-bottom: 0.5em;
            font-weight: 700;
          }

          .signIn-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $myViolet;
            padding: 0.25em 0.5em;
            width: 6em;
            text-align: center;
            border-radius: 1.5em;
            font-size: 0.95rem;
            color: $myViolet;
            font-weight: 700;
            transition: 0.3s ease-in-out;

            &:hover {
              background-color: $myViolet;
              color: #ffffff;
            }
          }
        }

      }
    }
  }

  .course-content {
    margin-top: 3.5em;
  }
}

@media screen and (max-width: 576px) {
  .singleCourse-page {
    .course-main-info {
      .course-details {
        .detail-item {
          border-inline-end: none;
          border-bottom: 3px solid rgba(#000000, 0.15);
          padding: 1em 0;
          justify-content: center;

          &.with-traniner {
            flex-direction: column;
            padding-top: 0;

            img {
              margin-inline-end: 0;
            }
          }

          .text-part {
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>